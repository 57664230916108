import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import HeroOther from '../components/HeroOther'
import { ThreeGrid } from '../components/Grid'
import { ProductHeroTriangle } from '../components/Triangle'
import Card from '../components/Card'
import ApiCard from '../components/ApiCard'
import FeatureCard from '../components/FeatureCard'
import CtaSection from '../components/CtaSection'
import NonStretchedImage from '../components/NonStretchedImage'
import ProductDemo from '../components/ProductDemo'
import AllStoredSection from '../sections/AllStoredSection'
import scrollToRef from '../utils/scrollToRef'

const applyRef = React.createRef();

class ProductPage extends React.Component {
  componentDidMount() {
    const url =window.location.href
    if (url.indexOf("#quick-demo") >-1) {
      scrollToRef(applyRef);
    }
  }
  componentDidUpdate() {
    const url =window.location.href
    if (url.indexOf("#quick-demo") >-1) {
      scrollToRef(applyRef);
    }
  }
 
  render() {
    const pageData = this.props.data.markdownRemark.frontmatter
    const hero = pageData.hero
    const integrateAppSection = pageData.integrateAppSection
    const advantagesSection = pageData.advantagesSection
    const allStoreSection = pageData.allStoreSection
    const integrateApiSection = pageData.integrateApiSection
    
    return(
      <Layout>
        <SEO 
          title={pageData.seoTitle}
          description={pageData.seoDescription}
        />
        <HeroOther data={hero}/>
        <ProductHeroTriangle />
        <section className="section is-black">
          <div className="container">
            <h2 className="section-tit1le is-white-text has-text-centered">
              {integrateAppSection.title}
            </h2>
            <p className="section-description is-white-text has-text-centered">
              {integrateAppSection.description}
            </p>
            <div className="full-width-image">
              <NonStretchedImage
                objectFit="contain"
                alt="integrateAppSection.title"
                className=""
                {...integrateAppSection.image}
              />
            </div>
          </div>
        </section>
        <div ref={applyRef} />
        <ProductDemo />
        <section className="section">
          <div className="container">
            <h2 className="section-title is-primary-text has-text-centered">
              {advantagesSection.title}
            </h2>
            <p className="description">
              {advantagesSection.description}
            </p>
            <ThreeGrid>
              {advantagesSection.list.map(item=>{
                return(
                  <FeatureCard cardData={item} />
                )
              })}
            </ThreeGrid>
          </div>
        </section>
        <AllStoredSection data={allStoreSection} />
        <section className="section">
          <div className="container">
            <h2 className="section-title is-primary-text has-text-centered">
              {integrateApiSection.title}
            </h2>
            <ThreeGrid>
              {integrateApiSection.list.map(item=>{
                return(
                  <ApiCard cardData={item} />
                )
              })}
            </ThreeGrid>
          </div>
        </section>
        <CtaSection bgClassName="is-gray-bg" />
      </Layout>
    )
  }
}


ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const pageQuery = graphql`
  query ProductPageById($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 534, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          buttons {
            text
            link
            linkNewWindow
            bg
          }
        }
        integrateAppSection {
          title
          description
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 350, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
        }
        advantagesSection {
          title
          description
          list {
            item
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
        allStoreSection {
          title
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxHeight: 447, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
          list {
            item
            description
          }
          buttons {
            text
            link
            linkNewWindow
            bg
          }
        }
        integrateApiSection {
          title
          list {
            item
            link
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 37, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
