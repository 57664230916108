import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from '../components/NonStretchedImage'
import { RightTriangleBorder } from '../components/Triangle'
import { v4 } from 'uuid'
import { cleanPath } from '../utils/path'

const ContentWrap = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	width: 100%;
	margin-top: 80px;
	padding-left: calc(50vw - 560px);
	.gatsby-image-wrapper {
		max-width: 100% !important;
	}
	@media(max-width: 1024px) {
		grid-template-columns: repeat(1, 1fr);
		& div:nth-child(2) {
  		order: -1;
  	}
	}
`
const FeaturesWrap = styled.div`
	display: grid;
	align-items: flex-start;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	& p {
		margin-top: 8px;
		color: black;
	}
	@media(max-width: 1024px) {
		margin-top: 40px;
		padding: 30px;
	}
	@media(max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 40px;
	}
`
const ButtonWrap = styled.div`
	display: flex;
	max-width: 400px;
	margin: 50px auto 0px;
	& a {
		width: 48%;
		&:nth-child(1) {
			margin-right: 3%;
		}
	}
	@media(max-width: 600px) {
		display: block;
	}
`
const Button = styled.button`
	width: 100%;
	background: ${props=>props.bg};
	border: 1px solid ${props=>props.bg};
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 12px;
  &:focus {
    outline: none !important;
  }
  &:hover {
  	opacity: 0.7;
    cursor: pointer;
  }
  @media(max-width: 600px) {
  	margin-bottom: 20px;
  }
`
const AllStoredSection = ({data}) => (
	<div>
		<section className="section is-gray-bg">
			<div className="container">
				<h2 className="section-title is-primary-text has-text-centered">
	        {data.title}
	      </h2>
			</div>
			<ContentWrap>
				<FeaturesWrap>
					{
						data.list.map(item=>{
							return(
								<div key={v4()}>
									<h5>{item.item}</h5>
									<p>{item.description}</p>
								</div>
							)
						})
					}
				</FeaturesWrap>
				<div>
					<NonStretchedImage
            objectFit="contain"
            alt="data.title"
            className=""
            {...data.image}
          />
				</div>
			</ContentWrap>
			<div className="container">
				<ButtonWrap>
					{
						data.buttons.map(item=>{
							if (item.linkNewWindow) {
								return(
									<a href={cleanPath(item.link)} target="_blank" rel="noreferrer">
										<Button bg={item.bg}>
											{item.text}
										</Button>
									</a>
								)
							} 
							return (
								<Link to={cleanPath(item.link)}>
									<Button bg={item.bg}>
										{item.text}
									</Button>
								</Link>
							)
						})
					}
				</ButtonWrap>
			</div>
		</section>
		<RightTriangleBorder />
	</div>
)

export default AllStoredSection

