import React from 'react'
import styled from 'styled-components'
import MatchIcon from './Icons/MatchIcon'
import NoMatchIcon from './Icons/NoMatchIcon'
import FirstSampleImg from '../img/image1.jpg'
import SecondSampleImg from '../img/image2.jpg'
import LoadingIcon from '../img/loading.svg'
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

const ContentGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 200px;

	margin-top: 70px;
	@media(max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 70px;
	}
`
const UploadWrap = styled.div`
	position: relative;
	height: 100%;
	max-height: 545px;
	max-width: 460px;
	margin: auto;
	text-align: center;
	& img {
		height: 90%;
		width: 100%;
		object-fit: cover;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
		border-radius: 6px;
		margin-bottom: 30px;
	}
	& input {
		display: none;
	}
	& label {
		background: #A7D1E9;
	  border: 1px solid #A7D1E9;
	  border-radius: 5px;
	  text-align: center;
	  font-size: 16px;
	  line-height: 24px;
	  color: white;
	  min-width: 160px;
	  padding: 12px;
	  text-transform: none;
	  &:focus {
	    outline: none !important;
	  }
	  &:hover {
	    background: #6F8D9E;
	    border: 1px solid #6F8D9E;
	    cursor: pointer;
	  }
	}
	@media(max-width: 768px){
		& label {
			width: 100%;
			max-width: 460px;
		}
	}
` 

const JsonWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 11;
	display: ${props=>props.display};
	border-radius: 6px;
	width: 100%;
	height: 90%;
	max-width: 460px;
	background: #142E3E;
	color: white;
	padding: 30px;
	text-align: left;
	& div {
		color: white;
	}
`

const ImageViewButton = styled.div`
	position: absolute;
	z-index: 11;
	top: 20px;
	right: 40px;
	display: ${props=>props.display};
	width: 100px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 5px;
	font-size: 14px;
	line-height: 30px;
	font-weight: bold;
	text-align: center;
	color: white;
	&:hover {
		cursor: pointer;
	}
`
const ArrayItem = styled.div`
	margin-left: 15px;
`
const FeatureItem = styled.div`
	margin-left: 30px;
`

const JsonViewButton = styled.div`
	position: absolute;
	z-index: 10;
	top: 20px;
	right: 40px;
	display: ${props=>props.display};
	width: 100px;
	background: #142E3E;
	border-radius: 5px;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	font-weight: bold;
	color: white;
	&:hover {
		cursor: pointer;
	}
`

const SubmitButton = styled.button`
	position: absolute;
	min-width: 100px !important;
	width: 100px;
	top: 50%;
	left: calc(50% - 50px);
	@media(max-width: 768px) {
		position: relative;
		width: 100%;
		max-width: 460px;
		left: calc(50% - 230px);
		margin: auto;
		margin-top: 50px;
	}
	@media(max-width: 480px) {
		left: 0;
	}
`
const MatchResult = styled.div`
	position: absolute;
	min-width: 100px !important;
	width: 120px;
	top: 50%;
	left: calc(50% - 60px);
	font-size: 24px;
	line-height: 44px;
	font-weight: 700;
	color: ${props=>props.color};
	text-align: center;
	& img {
		display: block;
		margin: auto;
	}
	& svg {
		display: block;
		margin: auto;
	}
	@media(max-width: 768px) {
		position: relative;
		width: 100%;
		max-width: 460px;
		left: calc(50% - 230px);
		margin: auto;
		margin-top: 50px;
	}
	@media(max-width: 480px) {
		left: 0;
	}
`
MatchResult.defaultProps = {
	color: "#75C3AC"
}
class ProductDemo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			firstFileData: null,
			secondFileData: null,
			firstFile: null,
			secondFile: null,
			match: "Match",
			isLoading: false,
			matchResult: null,
			isVisible: false,
		}
		this.onFirstFileSelect = this.onFirstFileSelect.bind(this)
		this.onSecondFileSelect = this.onSecondFileSelect.bind(this)
		this.showJson = this.showJson.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}
	
	onFirstFileSelect(e) {
	  let file = e.target.files[0];
	  if (file) {
	    const reader = new FileReader(file);
	    reader.readAsDataURL(file);
	    reader.onload = () => {
	      // set image and base64'd image data in component state
	      this.setState({
	        firstFileData: reader.result,
	        firstFile: file,
	        match: "",
	        isVisible: false,
	      });
	    }
	  }
	}
	
	onSecondFileSelect(e) {
		let file = e.target.files[0];
	  if (file) {
	    const reader = new FileReader(file);
	    reader.readAsDataURL(file);
	    reader.onload = () => {
	      // set image and base64'd image data in component state
	      this.setState({
	        secondFileData: reader.result,
	        secondFile: file,
	        match: "",
	        isVisible: false,
	      });
	    }
	  }
	}

	async onSubmit(e) {
		e.preventDefault();
		this.setState({
			isLoading: true
		})
		const token = await recaptchaRef.current.executeAsync();
		var myHeaders = new Headers();
		myHeaders.append("Content", "application/json");
		myHeaders.append("Accept", "application/json");

		var formdata = new FormData();
		formdata.append("g-recaptcha-response", token);
		formdata.append("image1", this.state.firstFile, "image1.jpg");
		formdata.append("image2", this.state.secondFile, "image2.jpg");

		var requestOptions = {
		   method: 'POST',
		   headers: myHeaders,
		   body: formdata,
		};

		fetch("https://biometricvisionapi.com/demo/compare", requestOptions)
		.then(response => response.json())
		.then(result => {
	   	this.setState({
	   		match: result.confidence,
	   		matchResult: result,
	   		isLoading: false,
	   	})
	   })
		.catch(error => console.log('error', error));

	}
	showJson() {
		let flag = this.state.isVisible
		this.setState({
			isVisible: !flag
		})
	}
	render() {
		const { firstFileData, secondFileData, isVisible, matchResult } = this.state

		return(
			<section className = "section is-gray-bg">
				<div className="container">
					<h2 className="section-tit1le is-primary-text has-text-centered">
	          Quick Demo
	        </h2>
	        <form
      			onSubmit={this.onSubmit}
					>
						<div style={{display: "none"}}>
							<ReCAPTCHA
								ref={recaptchaRef}
				        size="invisible"
				        sitekey="6LfVB6gZAAAAAH5q4iznJ1og9Pae-SaQsssqmzLx"
				      />
			      </div>
		        <ContentGrid>
		        	<UploadWrap>
		        		{firstFileData!=null?(
		        			<img src={firstFileData} alt="first-image"/>
		        		):(
		        			<img src={FirstSampleImg} alt="first-image"/>
		        		)}
								<input
									type="file"
									id="first"
									onChange={this.onFirstFileSelect}
								/>
								<label for="first">
									Upload image
								</label>
		        	</UploadWrap>
		        	<UploadWrap>
		        		{secondFileData!=null?(
		        			<img src={secondFileData} alt="second-image" />
		        		):(
		        			<img src={SecondSampleImg} alt="second-image"/>
		        		)}
		        		{matchResult !== null && this.state.match !== "" ?(
		        			<JsonViewButton onClick={this.showJson}>
		        				View JSON
		        			</JsonViewButton>
		        		):(
		        			<></>
		        		)}
		        		<JsonWrap display={isVisible?"block":"none"}>
		        			<ImageViewButton onClick={this.showJson}>
		        				View Image
		        			</ImageViewButton>
		        			{matchResult !== null?(
		        				<div>
			        				<div>&#123;</div>
										    <ArrayItem>"confidence": "{matchResult.confidence}",</ArrayItem>
										    <ArrayItem>"features": &#123;</ArrayItem>
										    	<FeatureItem>"leftEye": {matchResult.features.leftEye},</FeatureItem>
										    	<FeatureItem>"rightEye": {matchResult.features.rightEye},</FeatureItem>
										    	<FeatureItem>"leftBrow": {matchResult.features.leftBrow},</FeatureItem>
										    	<FeatureItem>"rightBrow": {matchResult.features.rightBrow},</FeatureItem>
										    	<FeatureItem>"forehead": {matchResult.features.forehead},</FeatureItem>
										    	<FeatureItem>"middle Forehead": {matchResult.features.middleForehead},</FeatureItem>
										    	<FeatureItem>"nose": {matchResult.features.nose},</FeatureItem>
										    	<FeatureItem>"philtrum": {matchResult.features.philtrum},</FeatureItem>
										    	<FeatureItem>"mouth": {matchResult.features.mouth},</FeatureItem>
										    	<FeatureItem>"jaw": {matchResult.features.jaw},</FeatureItem>
										    	<FeatureItem>"leftCheek": {matchResult.features.leftCheek},</FeatureItem>
										    	<FeatureItem>"rightCheek": {matchResult.features.rightCheek},</FeatureItem>
											  <ArrayItem>&#125;</ArrayItem>
											<div>&#125;</div>
			        			</div>
		        			):(
		        				<></>
		        			)}
		        		</JsonWrap>
		        		<input
									type="file"
									id="second"
									onChange={this.onSecondFileSelect}
								/>
								<label for="second">
									Upload image
								</label>
		        	</UploadWrap>
	        	</ContentGrid>
	        	{this.state.match==="" && !this.state.isLoading && this.state.firstFile !== null && this.state.secondFile !== null?(
	        		<SubmitButton type="submit" className="is-primary">
		        		Compare
		        	</SubmitButton>
	        	):(
		        	<></>
	        	)}
	        	{this.state.isLoading?(
	        		<MatchResult color="#6F8D9E">
	        			<img src={LoadingIcon} />
	        			Processing
	        		</MatchResult>
	        	):(
	        		<></>
	        	)}
	        	{ !this.state.isLoading && this.state.match === "Match"?(
	        			<MatchResult>
	        				<MatchIcon />
	        				{this.state.match}
	        			</MatchResult>
	        		):(
	        			<></>
	        		)
	        	}
	        	{ !this.state.isLoading && this.state.match === "No Match"?(
	        			<MatchResult color="#D95C5C">
	        				<NoMatchIcon />
	        				{this.state.match}
	        			</MatchResult>
	        		):(
	        			<></>
	        		)
	        	}
	        </form>
        </div>
			</section>
		)
	}
}

export default ProductDemo